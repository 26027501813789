import * as React from "react"

import {
  graphql,
  Link,
} from "gatsby"
import {
  GatsbyImage,
  getImage,
} from "gatsby-plugin-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const authors = data.allAuthorYaml.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <Bio />
      <div className="row">
        <div className="col-8">
          <ol style={{ listStyle: `none` }}>
            {posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug

              return (
                <li key={post.fields.slug}>
                  <article
                    className="post-list-item text-center"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <div className="row">
                        <div className="col-12 p-2">
                          <Link to={post.fields.slug} itemProp="url">
                            <GatsbyImage
                              className="w-full mb-2"
                              image={getImage(post.frontmatter.featuredImage)}
                              alt="Article"
                              backgroundColor={false}
                            />
                            <h2 className="text-primary">
                              <span itemProp="headline">{title}</span>
                            </h2>
                          </Link>
                        </div>
                      </div>
                      <small>{post.frontmatter.author.name} </small>
                      <small>{post.frontmatter.date}</small>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </section>
                  </article>
                </li>
              )
            })}
          </ol>
        </div>
        <div className="col-4">
          <h3>Authors</h3>
          <div>
            {authors.map(author => {
              const name = author.name
              const id = author.yamlId

              return (
                <div className="row">
                  <div className="col-12">
                    <Link to={"author/" + id} itemProp="url">
                      <span itemProp="">{name}</span>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          author {
            id
            name
          }
          date(formatString: "MMMM DD, YYYY")
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 667)
            }
          }
          title
        }
      }
    }
    allAuthorYaml(sort: { fields: [name], order: ASC }) {
      nodes {
        id
        name
        yamlId
      }
    }
  }
`
